import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import './index.css';
import { makeMainRoutes } from './routes';
import 'bootstrap/dist/css/bootstrap.css';
require('dotenv').config()

ReactDOM.render((
	<BrowserRouter>
	  {makeMainRoutes()}
	</BrowserRouter>
), document.getElementById('root'));
