import React, { Component } from 'react';
import loading from '../../img/loading-spinner.gif';

class Callback extends Component {
  render() {
    return (
      <div>
        <img src={loading} alt="loading"/>
      </div>
    );
  }
}

export default Callback;