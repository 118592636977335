import React, { Component } from 'react';
import './App.css';

import GoodsOrders from './components/GoodsOrders'
import ExpirationDateForToday from './components/ExpirationDateForToday'
// import UpcomingEvents from './components/UpcomingEvents'
//import UrsinsCorner from './components/UrsinsCorner'
// import MultimediaGallery from './components/MultimediaGallery'

class Home extends Component {
  render() {
    const { isAuthenticated } = this.props.auth;

    return (
        <div className="App">
        {
          isAuthenticated() && (
            <div className="App-content">
                <div className="row">
                  <div className="col-lg-9 col-sm-12">
                    <GoodsOrders auth={this.props.auth} />
                  </div>
                  <div className="col-lg-3 col-sm-12">
                    {/*<UpcomingEvents /> */}
                    <ExpirationDateForToday />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-9">
                    {/*<UrsinsCorner />*/}
                  </div>
                  <div className="col-lg-3 col-sm-12">
                    {/*<MultimediaGallery />*/}
                  </div>
                </div>
            </div>
          )
        }
        </div>
    );
  }
}

export default Home;
