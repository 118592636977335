import React, { Component } from 'react';
import moment from 'moment'
import './index.css'

const EXPIRATION_PERIOD = 121;

class ExpirationDateForToday extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
	    expirationDate: moment(), 
	  };
	}

	componentDidMount() {
	  this.updateDate();
	  this.intervalID = setInterval(
	    () => this.updateDate(),
	    60 * 60 * 1000
	  );
	}

	componentWillUnmount() {
	  clearInterval(this.intervalID);
	}

	updateDate() {
	  this.setState({
	    expirationDate: moment().add(EXPIRATION_PERIOD, 'days') ,
	  });
	}

	getDate() {
		return this.state.expirationDate.format("DD MMM YYYY").toUpperCase();
	}

	render() {
		return (
			<div className="dashboard-tile">
				<h1 className="dashboard-header-1">Production Info</h1>
				
				<div className="expiration-date">
					<h2 className="dashboard-header-2">Ablaufdatum für heute</h2>
					<div className="expiration-date-stamp">{this.getDate()}</div>
				</div>
			</div>
		)
	}
}

export default ExpirationDateForToday;