import React, { Component } from 'react';

class LiveClock extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
	    time: new Date()
	  };
	}

	componentDidMount() {
	  this.intervalID = setInterval(
	    () => this.tick(),
	    1000
	  );
	}

	componentWillUnmount() {
	  clearInterval(this.intervalID);
	}

	tick() {
	  this.setState({
	    time: new Date(),
	  });
	}

	getTime() {
		const locale = 'de-CH';
		const options = {
	    	weekday: 'long',
	    	month: 'long',
	    	year: 'numeric',
	    	day: 'numeric',
	    	hour: 'numeric',
	    	minute: 'numeric'
	    };

		return this.state.time.toLocaleString(locale, options);
	}

	render() {
		return (
			<div>
				{this.getTime()}
			</div>
		)
	}
}

export default LiveClock;