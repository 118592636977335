import React, { Component } from 'react';
import './index.css'
import standardRequest from '../../services/backend-requests.js'
import GoodsOrder from './GoodsOrder'

class GoodsOrders extends Component {
	state = {
		orders: []
	}

	componentWillMount() {
		this.fetchOrders();
		this.intervalID = setInterval(
		    () => this.fetchOrders(),
		    30 * 1000
		 );
	}

	componentWillUnmount() {
	  clearInterval(this.intervalID);
	}

	async fetchOrders() {
		const sortOrdersByDeliveryDate = function(orderA, orderB) {
			if (!orderB) {
				return 1;
			} else {
				return orderA.deliveryDatetime < orderB.deliveryDatetime ? -1 : 1
			}
		}

		const response = await standardRequest('/goods_orders/not_executed', 'GET', this.props.auth.accessToken);
		const orders = response.orders || [];
		this.setState({ orders: orders.sort(sortOrdersByDeliveryDate) });
	}

	listOrders() {
		let orders = this.state.orders;
		if (orders.length === 0) {
			return(
				<div>Keine offene Bestellungen.</div>
			)
		} else {
			return(
				orders.map((order) =>
					<GoodsOrder
						key={order.orderId}
						deliveryDatetime={order.deliveryDatetime}
						partnerName={order.partnerName}
						partnerSlug={order.partnerSlug}
						quantityPallets={order.quantityPallets}
						quantityBoxes={order.quantityBoxes}
						quantityBatteries={order.quantityBatteries}
						progress={order.progress}
						status={order.status}
						partnerOrderId={order.partnerOrderId}
					/>
				)
			)
		}
	}

	render() {
		return (
				<div className="dashboard-tile">
					<h1 className="dashboard-header-1">Bestellungen</h1>
					<div className="orders">{ this.listOrders() }</div>
				</div>
		);
	}
}

export default GoodsOrders;
