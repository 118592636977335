// import { updateTokens, getAccessToken } from './auth0';

async function makeStandardRequest(route, method, accessToken, header = {}, body = {}) {
  if (method === 'GET') {
    return fetch(`${process.env.REACT_APP_BACKEND_DOMAIN}/v1${route}`, {
      method,
      headers: {
        authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        ...header,
      },
    });
  } else if (method === 'POST' || method === 'DELETE' || method === 'PATCH') {
    return fetch(`${process.env.REACT_APP_BACKEND_DOMAIN}/v1${route}`, {
      method,
      headers: {
        authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        ...header,
      },
      body: JSON.stringify(body),
    });
  }
}

async function parseResponse(response, resType) {
  try {
    if (resType === 'blob') {
      return await response.blob();
    }
    return await response.json();
  } catch (error) {
    return response;
  }
}

export default async function standardRequest(route, method, accessToken, header, body, resType) {
  try {
    let response;
    let returnedResponse;
    let updateTokenRequest;

    response = await makeStandardRequest(route, method, accessToken, header, body);
    returnedResponse = await response;

    if (returnedResponse.status === 401) {
      //updateTokenRequest = await updateTokens();
      if (updateTokenRequest.status === 200) {
        //accessToken = localStorage.getItem('accessToken');
        response = await makeStandardRequest(route, method, accessToken, header, body);
        returnedResponse = await response;
        if (returnedResponse.status >= 400) {
          return {
            status: returnedResponse.status,
            error: returnedResponse.status,
          };
        }
        return parseResponse(returnedResponse, resType);
      }
      return {
        status: updateTokenRequest.status,
        error: updateTokenRequest.status,
      };
    } else if (returnedResponse.status !== 200 && returnedResponse.status >= 400) {
      return {
        status: returnedResponse.status,
        error: returnedResponse.status,
      };
    }
    return parseResponse(returnedResponse, resType);
  } catch (error) {
    return {
      error: 404,
    };
  }
}
