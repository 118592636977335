import React, { Component } from 'react';
import './App.css';
import logo from './img/chimpy_logo.png';

import LiveClock from './components/LiveClock'

class App extends Component {
  componentDidMount() {
    const { renewSession } = this.props.auth;

    if (localStorage.getItem('isLoggedIn') === 'true') {
      renewSession();
    }
  }

  goTo(route) {
    this.props.history.replace(`/${route}`)
  }

  login() {
    this.props.auth.login();
  }

  render() {
    const { isAuthenticated } = this.props.auth;

    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="header-logo" alt="Battere logo" />
          <div className="date-time">
            <LiveClock />
          </div>
        </header>
        <div className="App-content">

        {
          !isAuthenticated() && (
            <div className="row">
              <h1>Authentication expired.</h1>
              <button
                className="btn-margin"
                onClick={this.login.bind(this)}
              >
                Log In
              </button>
            </div>
          )
        }
        </div>
      </div>
    );
  }
}

export default App;
