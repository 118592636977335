import React, { Component } from 'react';
import PropTypes from "prop-types";
import { FaShoppingCart, FaBoxOpen, FaBatteryFull, FaQuestion, FaClock, FaCheck } from 'react-icons/fa';
import { IconContext } from "react-icons";

import chimpyLogo from '../../img/chimpy_logo.png';
import valoraDELogo from '../../img/valora_de_logo.png';
import valoraCHLogo from '../../img/valora_ch_logo.png';
import limboLogo from '../../img/limbo_logo.png';
import festivalsLogo from '../../img/festivals_logo.png';
import selectaLogo from '../../img/selecta_logo.png';

class GoodsOrder extends Component {
	componentWillMount() {
		//console.log(this.props.partnerSlug);
	}
	render() {
	  // Parse delivery datetime
	  const _deliveryDatetime = new Date(this.props.deliveryDatetime);
	  this.deliveryDatetime = {
		dateStr: _deliveryDatetime.toString(),
		weekDay: _deliveryDatetime.toLocaleString('de-CH', { weekday: 'long' }),
		month: _deliveryDatetime.toLocaleString('de-CH', { month: 'long' }),
		monthDay: _deliveryDatetime.getDate(),
		time: _deliveryDatetime.toLocaleTimeString('de-CH', { hour: '2-digit' })
	  };

	  this.batteryIcon = <FaBatteryFull />;
	  this.boxIcon = <FaBoxOpen />;
	  this.palletIcon = <FaShoppingCart />;

		// Get partner logo, quantity, and unit icon depending on partner
		switch (this.props.partnerSlug) {
			case 'valora_ch':
				this.logo = valoraCHLogo;
				this.quantity = this.props.quantityPallets || '?';
				this.unit = "pallets"
				this.unitIcon = this.palletIcon;
				break;
			case 'valora_de':
				this.logo = valoraDELogo;
				this.quantity = this.props.quantityPallets || '?';
				this.unit = "pallets"
				this.unitIcon = this.palletIcon;
				break;
			case 'selecta_ch':
				this.logo = selectaLogo;
				this.quantity = this.props.quantityBatteries || '?';
				this.unit = "batteries"
				this.unitIcon = this.batteryIcon;
				break;
			case 'festivals_ch':
				this.logo = festivalsLogo;
				this.quantity = this.props.quantityBoxes || '?';
				this.unit = "boxes"
				this.unitIcon = this.boxIcon;
				break;
			case 'production_chimpy':
				this.logo = limboLogo;
				this.quantity = this.props.quantityBoxes || '?';
				this.unit = "boxes"
				this.unitIcon = this.boxIcon;
				break;
			default:
				this.logo = chimpyLogo;
				this.quantity = this.props.quantityBoxes || '?';
				this.unit = "boxes"
				this.unitIcon = this.boxIcon;
		}

		// Parse progress, status, partnerOrderID
		this.progressValue = (this.props.progress * 100).toFixed(0);
		if (this.progressValue > 100) {
			this.progressValue = 100;
		}
		this.progressBarStr = this.progressValue.toString() + '%' ;
		let amountDone = this.props.progress * this.quantity;
		this.progressAmountDone = amountDone.toFixed(1)
		this.progressAmountMissing = (this.quantity - amountDone).toFixed(1);
		const statusesDE = {
			'confirmed': 'bestätigt',
			'planned': 'geplant',
			'ready': 'kommissioniert',
		}
		const statusIcons = {
			'confirmed': <FaClock />,
			'planned': <FaQuestion />,
			'ready': <FaCheck />,
		}
		this.status = statusesDE[this.props.status].toUpperCase();
		this.statusIcon = statusIcons[this.props.status];
		this.partnerOrderId = this.props.partnerOrderId;

		let orderQuantityDetail = [];

		if (this.unit !== "batteries") {
			orderQuantityDetail.push(
				<IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
					{ this.props.quantityBatteries } { this.batteryIcon }<br />
				</IconContext.Provider>
			);
		}

		if (this.unit !== "boxes") {
			orderQuantityDetail.push(
				<IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
					{ this.props.quantityBoxes } { this.boxIcon }<br />
				</IconContext.Provider>
			);
		}

		if (this.unit !== "pallets") {
			orderQuantityDetail.push(
				<IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
					{ this.props.quantityPallets } { this.palletIcon }<br />
				</IconContext.Provider>
			);
		}


		return(
			<div className="order-item row">
				<div className="col-lg-2 col-sm-6 col-xs-6 order-datetime">
					<div>
						<time dateTime={ this.deliveryDatetime.dateStr } className="calendar-icon">
						  <em className="day-of-week">{ this.deliveryDatetime.weekDay }</em>
						  <strong className="month">{ this.deliveryDatetime.month }</strong>
						  <span className="day-of-month">{ this.deliveryDatetime.monthDay }</span>
						  <span className="time">{ this.deliveryDatetime.time }</span>
						</time>
					</div>

				</div>

				<div className="col-lg-2 col-sm-6 col-xs-6">
					<img src={ this.logo } alt={ this.props.partnerName } className="partner-logo" />
					<div className="order-id">{ this.partnerOrderId }</div>
					<div className="order-status">{ this.statusIcon } { this.status }</div>
				</div>

				<div className="col-lg-3 col-sm-6 col-xs-6">
					<div className="order-quantity">
						<IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
							{ this.quantity } { this.unitIcon }
						</IconContext.Provider>
					</div>

					<div className="order-quantity-detail">
						{ orderQuantityDetail }
					</div>
				</div>

				<div className="col-lg-5 col-sm-6 col-xs-6">
					<progress max="100" value={ this.progressValue } data-label={ this.progressBarStr }></progress>
					<div className="order-progress"><span className="big-number">{ this.progressAmountDone } </span>gemacht</div>
					<div className="order-progress"><span className="big-number">{ this.progressAmountMissing } </span>fehlen noch</div>
				</div>
			</div>
		);
	}
}

GoodsOrder.propTypes = {
	deliveryDatetime: PropTypes.string.isRequired,
	partnerName: PropTypes.string.isRequired,
	partnerSlug: PropTypes.string.isRequired,
	quantityPallets: PropTypes.number,
	quantityBoxes: PropTypes.number,
	quantityBatteries: PropTypes.number,
	progress: PropTypes.number.isRequired,
	status: PropTypes.string.isRequired,
	partnerOrderId: PropTypes.string
};

export default GoodsOrder;
